<tdt-submenu></tdt-submenu>
<div>
    <h1 id="procedure">Procedure d'inscription</h1>
    <article *ngFor="let step of steps$ | async">
        <div class="step-container" [style.background]="step.couleur">
            <h1>{{ step.titre }}</h1>
            @if (step.description) {
                <h2 [innerHTML]="step.description | safeHtml"></h2>
            }
        </div>
        <div class="step-arrow" [style.border-top-color]="step.couleur"></div>
    </article>
</div>
